<template>
  <div v-if="summary" class="tg-mobile-body lg:tg-desktop-body">
    <router-link
      :to="{ name: 'PropertyVisitTimeSlots' }"
      class="link tg-mobile-link-small"
    >
      <i class="far fa-chevron-left mr-2" />
      {{ $t('book_another_visit') }}
    </router-link>
    <h3 class="my-5 tg-mobile-header-3 lg:tg-desktop-header-3">
      {{ $t('visit_booked') }}
    </h3>
    <p class="my-5 tg-mobile-body lg:tg-desktop-body">
      {{ $t('visit_booking_confirmed') }}
    </p>
    <!-- Break tags are used instead of flex-col because the margin-collapse doesn't work in flex -->
    <i class="w-4 my-4 mr-4 fal fa-calendar text-gray-cc" />
    {{ formatDateForLocale(summary.slot.datetime_start) }}
    <br />
    <i class="w-4 my-4 mr-4 fal fa-clock text-gray-cc" />
    {{ formatHHmm(summary.slot.datetime_start) }} -
    {{ formatHHmm(summary.slot.datetime_end) }}
    <br />
    <a :href="ics" class="link tg-mobile-link lg:tg-desktop-link">
      <i class="w-4 my-4 mr-4 fal fa-link" />
      {{ $t('add_to_your_own_calendar') }}
    </a>
    <!-- <button
      type="button"
      class="px-5 py-0.5 my-8 mx-auto block tg-mobile-button-small lg:tg-desktop-body text-white bg-brand-primary rounded-full shadow-card hover:bg-opacity-84"
    >
      Terug naar soldandset.be
    </button> -->
  </div>
</template>

<script>
import { formatHHmm, formatDateForLocale } from '@/helpers';

export default {
  name: 'PropertyVisitSummary',
  props: {
    summary: {
      type: Object,
      default: null
    }
  },
  computed: {
    ics() {
      return process.env.VUE_APP_CALENDAR_API_URL + this.summary.ics_url;
    }
  },
  created() {
    if (this.summary === null) {
      this.$store.dispatch('snackbar/show', {
        type: 'error',
        message: this.$t('choose_visit_time'),
        route: { name: 'PropertyVisitTimeSlots' }
      });
    }
  },
  methods: {
    formatHHmm,
    formatDateForLocale
  }
};
</script>
